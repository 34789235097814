import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/PageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Olá!`}</h1>
    <p>{`Em primeiro lugar, agradeço a todas as pessoas que se interessaram pelo projeto e se candidataram no processo. Vou continuar utilizando esse espaço para disponibilizar informações aos participantes.`}</p>
    <h2>{`Andamento do Processo`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`18/09 - `}<strong parentName="p">{`08 pessoas`}</strong>{` foram comunicadas sobre a desclassificação, pois não atendem os requisitos básicos obrigatórios do projeto.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`19/09 - `}<strong parentName="p">{`03 pessoas`}</strong>{` que atendem todos os requisitos de Full Stack + Diferenciais de Backend, foram selecionadas para bater um papo comigo via Hangouts.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`20/09 - Bati um papo com as `}<strong parentName="p">{`03 pessoas`}</strong>{` selecionadas. `}<br />{`
`}<strong parentName="p">{`Pessoa 01`}</strong>{` atendeu muito bem minhas expectativas. Tem bastante experiência na área e realmente conhece a Stack que será utilizada. `}<br />{`
`}<strong parentName="p">{`Pessoa 02`}</strong>{` atende todos os requisitos, mas ainda falta um pouco de experiência em alguns deles.`}<br />{`
`}<strong parentName="p">{`Pessoa 03`}</strong>{` recebeu o feedback sobre a desclassificação, pois ela não tem disponibilidade integral para trabalhar no projeto.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Nota`}</strong>{`: Todas as pessoas que continuam participando do processo estão cientes do andamento do processo. Toda a comunicação está sendo feita através de email.`}</p>
    <h2>{`Processo`}</h2>
    <p>{`Com o intuito de ser o mais transparente possível em todo o processo, resolvi fornecer algumas informações que acho relevantes. Eu iniciei esse processo no dia 16 de setembro de 2019 às 17h00 e encerrei no dia 17 de setembro às 22h00.`}</p>
    <p>{`Os dados que quero compartilhar são os seguintes:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Esta página teve `}<strong parentName="p">{`1.049 acessos`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`5% das pessoas`}</strong>{` que visitaram a página se candidataram`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`50 pessoas`}</strong>{` estão participando do processo`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`08 pessoas (16%)`}</strong>{` não atendem os requisitos básicos obrigatórios`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`09 pessoas (18%)`}</strong>{` não tem conta no Twitter`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`100% das pessoas`}</strong>{` tem conta no GitHub`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`16 pessoas (32%)`}</strong>{` não indicaram repositórios para comprovar experiência (sem preenchimento, não tem, repos privados, etc.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`12 pessoas (24%)`}</strong>{` indicaram repositórios de cursos da Rocketseat 🚀 (GoBarber foi o campeão - 07 pessoas)`}</p>
      </li>
    </ul>
    <p>{`Das 42 pessoas que atendem todos os requisitos básicos obrigatórios:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`27 pessoas (64%)`}</strong>{` atendem todos os requisitos obrigatórios de `}<strong parentName="p">{`Backend`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`02 pessoas (5%)`}</strong>{` atendem todos os diferenciais de `}<strong parentName="p">{`Backend`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`30 pessoas (71%)`}</strong>{` atendem todos os requisitos obrigatórios de `}<strong parentName="p">{`Frontend`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`09 pessoas (21%)`}</strong>{` atendem todos os diferenciais de `}<strong parentName="p">{`Frontend`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`17 pessoas (40%)`}</strong>{` atendem todos os requisitos obrigatórios de `}<strong parentName="p">{`Full Stack`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Nenhuma pessoa`}</strong>{` atende todos os diferenciais de `}<strong parentName="p">{`Full Stack`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Uma pessoa conhece `}<inlineCode parentName="p">{`GitHub`}</inlineCode>{`, mas não conhece `}<inlineCode parentName="p">{`git`}</inlineCode>{`, vou considerar como um equívoco no preenchimento do formulário 😉`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Uma pessoa me mandou um repo com `}<inlineCode parentName="p">{`java`}</inlineCode>{`, `}<inlineCode parentName="p">{`spring boot`}</inlineCode>{` e `}<inlineCode parentName="p">{`angularjs`}</inlineCode>{` 🙈 🙉 🙊`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Última atualização das informações`}</strong>{`: 19 de setembro de 2019 às 19h45.`}</p>
    <h2>{`Apresentação`}</h2>
    <p>{`Recentemente fechei um projeto para a área de segurança da informação e estou em busca de um time para me auxiliar nessa missão. Minha ideia é encontrar 01 profissional Full Stack ou 02 profissionais Backend e Frontend.`}</p>
    <p>{`Caso você esteja interessado, eu disponibilizei algumas informações sobre o projeto e quais são os requisitos técnicos necessários que estou buscando para encontrar essas pessoas. Parto do pressuposto que você é uma pessoa `}<strong parentName="p">{`responsável`}</strong>{` e `}<strong parentName="p">{`comprometida`}</strong>{`.`}</p>
    <h2>{`Projeto`}</h2>
    <p>{`Desenvolver uma solução em microserviços para atender uma demanda de um projeto da área de segurança da informação. O projeto já passou pela fase de MVP e irá evoluir para um produto/serviço.`}</p>
    <p><strong parentName="p">{`Duração do projeto`}</strong>{`: 12 meses`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Regime de Contratação`}</strong>{`: PJ`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Local de Trabalho`}</strong>{`: Remoto (com possibilidade de encontros presenciais)`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Disponibilidade`}</strong>{`: Período integral (full time)`}<br parentName="p"></br>{`
`}<strong parentName="p">{`Salário`}</strong>{`: de R$ 7.896,00 à R$ 12.096,00 (dependendo do nível)`}</p>
    <h2>{`Cliente`}</h2>
    <p>{`Meu cliente é uma empresa com mais de 18 anos no mercado, pioneiros no mapeamento do comportamento e reputação do consumidor final. Atua nos segmentos E-Commerce, Seguros, Telecomunicações, Venda Direta, Varejo Físico e Serviços Financeiros (bancos e fintechs).`}</p>
    <h2>{`Requisitos básicos obrigatórios`}</h2>
    <p>{`Todos os candidatos devem atender os requisitos abaixo.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Sólidos conhecimentos em `}<strong parentName="p">{`JavaScript`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`modules`}</inlineCode>{`, `}<inlineCode parentName="p">{`const/let`}</inlineCode>{`, `}<inlineCode parentName="p">{`arrow functions`}</inlineCode>{`, `}<inlineCode parentName="p">{`template strings`}</inlineCode>{`, `}<inlineCode parentName="p">{`destructuring`}</inlineCode>{`, `}<inlineCode parentName="p">{`rest/spread`}</inlineCode>{`, `}<inlineCode parentName="p">{`array methods`}</inlineCode>{`, `}<inlineCode parentName="p">{`object methods`}</inlineCode>{`, `}<inlineCode parentName="p">{`callback`}</inlineCode>{`, `}<inlineCode parentName="p">{`promise`}</inlineCode>{`, `}<inlineCode parentName="p">{`async/await`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Conhecimentos em `}<strong parentName="p">{`git`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`init`}</inlineCode>{`, `}<inlineCode parentName="p">{`status`}</inlineCode>{`, `}<inlineCode parentName="p">{`add`}</inlineCode>{`, `}<inlineCode parentName="p">{`commit`}</inlineCode>{`, `}<inlineCode parentName="p">{`pull`}</inlineCode>{`, `}<inlineCode parentName="p">{`push`}</inlineCode>{`, `}<inlineCode parentName="p">{`branch`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Conhecimentos em `}<strong parentName="p">{`GitHub`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`repos`}</inlineCode>{`, `}<inlineCode parentName="p">{`branchs`}</inlineCode>{`, `}<inlineCode parentName="p">{`issues`}</inlineCode>{`, `}<inlineCode parentName="p">{`pull requests`}</inlineCode></p>
      </li>
    </ul>
    <h2>{`Vaga Backend`}</h2>
    <h3>{`Requisitos obrigatórios`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Sólidos conhecimentos em `}<strong parentName="p">{`MongoDB`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`mongoose`}</inlineCode>{`, `}<inlineCode parentName="p">{`models`}</inlineCode>{`, `}<inlineCode parentName="p">{`CRUD`}</inlineCode>{`, `}<inlineCode parentName="p">{`aggregation`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Experiência comprovada em `}<strong parentName="p">{`Express ou Koa`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`router`}</inlineCode>{`, `}<inlineCode parentName="p">{`middlewares`}</inlineCode>{`, `}<inlineCode parentName="p">{`jwt`}</inlineCode>{`, `}<inlineCode parentName="p">{`authentication`}</inlineCode>{`, `}<inlineCode parentName="p">{`authorization`}</inlineCode>{`, `}<inlineCode parentName="p">{`error handler`}</inlineCode></p>
      </li>
    </ul>
    <h3>{`Diferenciais`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RabbitMQ`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`connection`}</inlineCode>{`, `}<inlineCode parentName="p">{`channel`}</inlineCode>{`, `}<inlineCode parentName="p">{`queue`}</inlineCode>{`, `}<inlineCode parentName="p">{`durable`}</inlineCode>{`, `}<inlineCode parentName="p">{`prefetch`}</inlineCode>{`, `}<inlineCode parentName="p">{`ack`}</inlineCode>{`, `}<inlineCode parentName="p">{`nack`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Docker`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`Dockerfile`}</inlineCode>{`, `}<inlineCode parentName="p">{`build`}</inlineCode>{`, `}<inlineCode parentName="p">{`run`}</inlineCode>{`, `}<inlineCode parentName="p">{`docker-compose`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Kubernetes`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`kubectl`}</inlineCode>{`, `}<inlineCode parentName="p">{`pod`}</inlineCode>{`, `}<inlineCode parentName="p">{`deployment`}</inlineCode>{`, `}<inlineCode parentName="p">{`service`}</inlineCode>{`, `}<inlineCode parentName="p">{`secret`}</inlineCode>{`, `}<inlineCode parentName="p">{`ingress`}</inlineCode>{`, `}<inlineCode parentName="p">{`job`}</inlineCode></p>
      </li>
    </ul>
    <h2>{`Vaga Frontend`}</h2>
    <h3>{`Requisitos obrigatórios`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Sólidos conhecimentos em `}<strong parentName="p">{`HTML5`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Sólidos conhecimentos em `}<strong parentName="p">{`CSS 3`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`grid`}</inlineCode>{`, `}<inlineCode parentName="p">{`flexbox`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Experiência comprovada em `}<strong parentName="p">{`React`}</strong>{` `}<br />{`
`}<inlineCode parentName="p">{`create-react-app`}</inlineCode>{`, `}<inlineCode parentName="p">{`react-router-dom`}</inlineCode>{`, `}<inlineCode parentName="p">{`components`}</inlineCode>{`, `}<inlineCode parentName="p">{`hoc`}</inlineCode>{`, `}<inlineCode parentName="p">{`state`}</inlineCode>{`, `}<inlineCode parentName="p">{`props`}</inlineCode>{`, `}<inlineCode parentName="p">{`hooks`}</inlineCode>{`, `}<inlineCode parentName="p">{`styled-components`}</inlineCode></p>
      </li>
    </ul>
    <h3>{`Diferenciais`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`UX/UI`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Figma`}</strong></p>
      </li>
    </ul>
    <h2>{`Vaga Full Stack`}</h2>
    <h3>{`Requisitos obrigatórios`}</h3>
    <ul>
      <li parentName="ul">{`Requisitos obrigatórios `}<strong parentName="li">{`Backend`}</strong>{` + `}<strong parentName="li">{`Frontend`}</strong></li>
    </ul>
    <h3>{`Diferenciais`}</h3>
    <ul>
      <li parentName="ul">{`Diferenciais `}<strong parentName="li">{`Backend`}</strong>{` + `}<strong parentName="li">{`Frontend`}</strong></li>
    </ul>
    <h2>{`Quero me candidatar`}</h2>
    <p>{`O processo para se candidatar foi encerrado, pois chegamos a `}<strong parentName="p">{`50 candidatos`}</strong>{`. Agradeço o interesse pelo projeto e quem sabe em uma próxima oportunidade. 😉`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      